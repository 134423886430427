import React from 'react';

import type { IJoinCryptomusData } from '../main-page.data';
import { joinCryptomusData } from '../main-page.data';

import { getLocaleLink } from 'cryptomus-aurora-kit';
import { useTranslations } from 'next-intl';

import styles from './join-cryptomus-block.module.scss';

interface IJoinCryptomusBlockPropsTypes {
  locale: string;
}

const JoinCryptomusBlock: React.FC<IJoinCryptomusBlockPropsTypes> = ({
  locale,
}) => {
  const t = useTranslations('indexPage');

  const getLink = (item: IJoinCryptomusData) => {
    if (item.linksForLocales && locale in item.linksForLocales)
      return item.linksForLocales[locale];

    return item?.withLocale ? getLocaleLink(item.link, locale) : item.link;
  };

  return (
    <div className={styles.container}>
      <h2>{t('Join Cryptomus Community')}</h2>

      <div className={styles.container__links}>
        {joinCryptomusData?.map((item) => (
          <a key={item.link} href={getLink(item)} target='_blank'>
            {item.image}
          </a>
        ))}
      </div>
    </div>
  );
};

export default JoinCryptomusBlock;
