import type { ReactNode } from 'react';

import type { ITradingBadgeProps } from 'components/main-page/our-products/our-products-item/trading-badge/trading-badge';

import MailIcon from 'icons/main/mail-icon.svg';
import TelegramIcon from 'icons/main/telegram-icon.svg';
import XComIcon from 'icons/main/x-com-icon.svg';

export const MAIN_PAGE_STATISTICS = [
  {
    count: '200M',
    label: 'Transactions',
  },
  {
    count: '18',
    label: 'Cryptocurrencies',
  },
  {
    count: '350K+',
    label: 'Users',
  },
  {
    count: '200+',
    label: 'Countries',
  },
];

export interface IOurProduct {
  description: string;
  link: string;
  row: number;
  title: string;
  badgeProps?: ITradingBadgeProps;
  icon?: ReactNode;
  link_type?: 'internal' | 'external';
  mobileBackground?: string;
  mobileBackgroundHeight?: string;
  mobileBackgroundWidth?: string;
  mobile_icon?: ReactNode;
  type?: string;
}

export interface IPartnershipDataItem {
  description: string;
  hover_icon: string;
  icon: string;
  link: {
    href: string;
    type: string;
  };
  title: string;
}

export const PARTNERSHIP_DATA: IPartnershipDataItem[] = [
  {
    description:
      'Invite your friends to Cryptomus and earn up to 50% of the fees they pay',
    hover_icon: '/icons/main/render_partnership_article_referal_hover 1.png',
    icon: '/icons/main/render-partnership-article-referal-default-1.svg',
    link: {
      href: 'https://app.cryptomus.com/settings/referral',
      type: 'external',
    },
    title: 'Referral',
  },
  {
    description:
      'Refer your audience to Cryptomus and get a share of our fees and other bonuses',
    hover_icon: '/icons/main/render_partnership_article_affiliate_hover 1.png',
    icon: '/icons/main/render-partnership-article-affiliate-default-1.svg',
    link: {
      href: '/affiliate',
      type: 'internal',
    },
    title: 'Affiliate',
  },
  {
    description:
      'Have your users trade on Cryptomus to get access to maximum profits and special bonuses',
    hover_icon: '/icons/main/render_partnership_article_broker_hover 1.png',
    icon: '/icons/main/render-partnership-article-broker-default-1.svg',
    link: {
      href: '/broker',
      type: 'internal',
    },
    title: 'Broker',
  },
  {
    description: 'Offer the best trading conditions to traders worldwide',
    hover_icon:
      '/icons/main/render_partnership_article_market_maker_hover 1.png',
    icon: '/img/main/render-partnership-article-market-maker-default-1.png',
    link: {
      href: '/market-maker',
      type: 'internal',
    },
    title: 'Market maker',
  },
];

export interface IJoinCryptomusData {
  image: JSX.Element;
  link: string;
  linksForLocales?: Record<string, string>;
  withLocale?: boolean;
}

export const joinCryptomusData: IJoinCryptomusData[] = [
  {
    image: <XComIcon />,
    link: 'https://x.com/cryptomus',
    withLocale: false,
  },
  {
    image: <TelegramIcon />,
    link: 'https://t.me/cryptomus',
    withLocale: false,
  },
  {
    image: <MailIcon />,
    link: 'https://t.me/+Kkqa3Du784RiYjM6',
    linksForLocales: {
      fr: 'https://t.me/+Ja-Eg4sNFgY4OTY6',
      zh: 'https://t.me/+2Orp5KMZ-pI1MTc6',
    },
  },
];
